<template>
    <div class="bg-gray-100 p-2 gap-2 max-w-full flex items-center flex-1 rounded-md border-2 justify-between border-transparent transition-all duration-400" @click.stop="$emit('handleSelect', data)" :class="activeElement && activeElement.id === data.id && 'border-primary'">
        <div class="flex flex-col max-w-full w-1/2">
            <span v-tippy :content="fetchLabel(data.label)" class="font-semibold text-sm capitalize truncate" :title="fetchLabel(data.label)">{{ data.label }}</span>
            <span v-tippy :content="fetchLabel(data.name)" class="text-xs truncate" :title="fetchLabel(data.name)">{{ data.name }}</span>
        </div>
        <svg v-if="data.is_cascaded" title="Cascaded" viewBox="0 0 17 14" fill="#0D69D5" xmlns="http://www.w3.org/2000/svg" class="w-5">
            <path
                d="M6.866 3.127h8.884a.629.629 0 00.626-.627.629.629 0 00-.626-.626H6.866a.629.629 0 00-.627.626c0 .345.282.627.627.627zM15.746 6.865h-5.33a.629.629 0 00-.626.627c0 .345.282.627.626.627h5.33a.629.629 0 00.627-.627.629.629 0 00-.627-.627zM15.746 11.456h-5.33a.629.629 0 00-.626.627c0 .345.282.627.626.627h5.33a.629.629 0 00.627-.627.629.629 0 00-.627-.627zM6.985 5.716H4.967a.769.769 0 00-.768.767v.386H2.617V4.32h.542a.769.769 0 00.768-.768V1.538a.769.769 0 00-.768-.767H1.14a.769.769 0 00-.768.767v2.018c0 .424.345.768.768.768h.222v7.85c0 .345.282.627.627.627H4.2v.382c0 .423.344.768.767.768h2.018a.769.769 0 00.768-.768v-2.018a.769.769 0 00-.768-.768H4.967a.769.769 0 00-.768.768v.382H2.617V8.125h1.582v.376c0 .423.345.768.768.768h2.018a.769.769 0 00.768-.768V6.483a.769.769 0 00-.768-.767z"
            ></path>
        </svg>
        <div class="flex flex-col w-24">
            <span class="px-2 py-0.5 mb-2 rounded-full bg-white text-2xs font-semibold text-center truncate shadow" :title="data.type"> {{ data.type }} </span>
            <span class="px-2 py-0.5 rounded-full bg-gray-300 text-2xs font-semibold text-center truncate shadow capitalize" :title="fetchSource"> {{ fetchSource }} </span>
        </div>
    </div>
</template>

<script>
import { fetchLabel } from '@shared/utils/functions';
export default {
    name: "field-list-item",
    props: {
        data: {
            type: Object,
            default: () => {},
        },
        activeElement: {
            type: Object,
            default: () => {},
        },
    },
    components: {},
    methods: {  
        fetchLabel,
        },
    computed: {
        fetchSource() {
            if (this.data?.source === 'system_fields') {
                return "System Fields";
            } else if (this.data?.source === 'cloned_system_fields') {
                return "Cloned System Field";
            } else if (this.data?.source === 'custom') {
                return "Custom";
            } else {
                return "";
            }
        },   
    },
};
</script>

<style style="scss" scoped></style>
