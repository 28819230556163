<template>
    <div class="">
        
        <div class="py-2 px-2 text-sm">Since you have selected a field with gap validation attribute set to true, this block will automatically be marked as a gap validation block. If this check is not a gap validation check please select some other timeline field in which gap validation is marked as false.</div>
        <div class="py-2 px-2">
            <div class="py-2 flex justify-between items-center">
                <label for="" class="font-semibold">Valid period (days)</label>
                <input min=0 v-model="gap_validation.validPeriod"  class="border border-gray-400 rounded-lg px-2 w-40"  type="number" />
            </div>
            <div class="py-2 flex justify-between items-center">
                <label for="" class="font-semibold">History (months)</label>
                <input min=0 v-model="gap_validation.history" class="border border-gray-400 rounded-lg px-2 w-40"  type="number" />
            </div>
        </div>
    </div>
</template>

<script>
// import Loader from "@shared/loader";

export default {    
    name: "gap-validation",
    props: {
        gap_validation:{
            type:Object,
            default: () => {}
        }
    },
    components: {
        
    },
    data() {
        return {
        };
    },
    methods: {},
    computed: {},
};
</script>

<style style="scss" scoped></style>
